import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Services | "Смак України: Майстерність Традиційної Кухні"
			</title>
			<meta name={"description"} content={"Чому кулінарну подорож в світ Української кухні варто саме з нами! "} />
			<meta property={"og:title"} content={"Services | \"Смак України: Майстерність Традиційної Кухні\""} />
			<meta property={"og:description"} content={"Чому кулінарну подорож в світ Української кухні варто саме з нами! "} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="70px 0 50px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-5">
			<Text
				margin="0px 0px 00px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Зміст курсу
			</Text>
			<Text margin="0px 0px 80px 0px" font="--headline3" text-align="center">
				Чого ви навчитесь:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Традиційні Рецепти
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							На наших курсах ви дізнаєтеся автентичні рецепти, передані з покоління в покоління, із секретами приготування, які роблять кожну страву справжньою майстерністю.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Історія та Культура
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							Ми розповімо вам про історію та культурний контекст кожної страви, щоб ви могли краще розуміти її походження та місце в українській культурі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Техніки та Секрети
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Ви навчитеся основним кулінарним технікам, необхідним для готування цих страв, а також дізнаєтеся про секрети, які додають справжній смак та характер кожному блюду.
							<br />
							<br />
							<br />
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Практичні навички
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							Під керівництвом наших досвідчених кулінарних майстрів ви отримаєте можливість відпрацювати кожний етап приготування страви, забезпечуючи практичний досвід.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							5
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Смак та Подарунки
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							На завершення кожного заняття ви зможете насолодитися результатами своєї роботи і спробувати справжні українські страви. Крім того, ви матимете можливість приготувати подарунки для вашої родини та друзів.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center">
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
					max-width="200px"
					text-align="center"
				>
					Взяти Участь
				</Link>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15:48:05.867Z"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/georgian-peas-soup-table_140725-9218.jpg?v=2023-10-06T15%3A48%3A05.867Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15:48:05.840Z"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-women-with-tablet-kitchen_23-2148942586.jpg?v=2023-10-06T15%3A48%3A05.840Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Запрошуємо вас приєднатися до нас і подарувати собі та своїм близьким незабутню кулінарну подорож у світ смаків, що проникають українську культуру та традиції.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});